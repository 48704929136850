<template>
  <b-card-code>
<!--    <div class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal()">
          Add new
        </b-button>
    </div>-->
    <!-- Email View/Detail -->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="employee_subjects"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'subject_id'">
          {{ subjectByID(props.row.subject_id) }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <!-- <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'shift_id'">
          {{ shiftByID(props.row.shift_id) }}
        </span> -->
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'section_id'">
          {{ sectionById(props.row.section_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <router-link :to="{path:`/employee/task/view/attendance${props.row.classes_id}/${props.row.version_id}/${props.row.shift_id}/${props.row.wing_id}/${props.row.student_group_id}/${props.row.section_id}/${props.row.academic_year_id}/${props.row.subject_id}`}">
                  <b-button :variant="'secondary'">
                    <feather-icon class="text-body " icon="EyeIcon" size="15" />
                  </b-button>
                </router-link>
                <router-link :to="{path:`/employee/task/take/attendance${props.row.classes_id}/${props.row.version_id}/${props.row.shift_id}/${props.row.wing_id}/${props.row.student_group_id}/${props.row.section_id}/${props.row.academic_year_id}/${props.row.subject_id}`}">
                  <b-button :variant="'primary'">
                    <feather-icon class="text-body " icon="UploadIcon" size="15" />
                  </b-button>
                </router-link>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import apiCall from "@/libs/axios";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'Attendance',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Subject',
          field: 'subject_id',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Version',
          field: 'version_id',
        },
        {
          label: 'Shift',
          field: 'shift_id',
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Section',
          field: 'section_id',
        },
        {
          label: 'Academic year',
          field: 'academic_year_id',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      columns10: [
        {
          label: 'Subject',
          field: 'Subject',
        },
        {
          label: 'Class',
          field: 'Class',
        },
        {
          label: 'Version',
          field: 'Version',
        },
        {
          label: 'Shift',
          field: 'Shift',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Section',
          field: 'Section',
        },
        {
          label: 'Academic year',
          field: 'AcademicYear',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  methods:{
    view(info){
      this.$router.push({path:`employee-course-material${info.classes_id}/${info.version_id}/${info.shift_id}/${info.student_group_id}/${info.section_id}/${info.academic_year_id}/${info.subject_id}`});
    },
    pdfGenerate() {
      let title = "Attendance";
      let clm = ['Subject','Class','Version','Shift','StudentGroup','Section','AcademicYear','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'attendance_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['employee_subjects','academic_years',
      'versionByID','shiftByID','wingByID','student_groupByID','academic_yearByID','classByID','subjectByID',
    'sections','sectionById','subjects']),
    getData() {
      return this.employee_subjects.map(item => ({
        Subject: item.subject_id ? this.subjectByID(item.subject_id) : '',
        Class: item.classes_id ? this.classByID(item.classes_id) : '',
        Version: item.version_id ? this.versionByID(item.version_id) : '',
        Shift: item.shift_id ? this.shiftByID(item.shift_id) : '',
        StudentGroup: item.student_group_id ? this.student_groupByID(item.student_group_id) : '',
        Section: item.section_id ? this.sectionById(item.section_id) : '',
        AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    },
    cls(){return this.$store.getters.classes},
    vs(){return this.$store.getters.versions},
    sf(){return this.$store.getters.shifts},
    wg(){return this.$store.getters.wings},
    sg(){return this.$store.getters.student_groups},
  },
  created() {
    if(this.employee_subjects.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE_SUBJECT');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.cls.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.vs.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.sf.length <1) this.$store.dispatch('GET_ALL_SHIFT');
    if(this.wg.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.sg.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.subjects.length <1) this.$store.dispatch('GET_ALL_SUBJECT');
    if(this.sections.length <1) this.$store.dispatch('GET_ALL_SECTION');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>